<template>
  <div id="Manage">
    <!-- 数据代理商  -->
    <!-- Dialog 对话框 增加、编辑-->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonTabForm :form="openForm"
                     :tabLabel="TabsFormLabel"
                     :activeTabName.sync="activeTabName"
                     ref="elTable"
                     @tabHandleClick="swichTab"
                     @handleRemove="handleRemove"
                     @modifychecked2='getSupplyID'
                     @handlePreview="handlePreview"></CommonTabForm>

      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(openForm)">{{dialogBtnName}}</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 审核表单-->
    <el-dialog :title="dialogFonfigAudit.auditName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigAudit.isShowDialogVisible">
      <CommonForm :form="auditForm"
                  class="dialogAuditForm"
                  :formLabel="auditFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="danger"
                   @click="clickSubmitAuditData(2, auditForm)">驳 回</el-button>
        <el-button type="success"
                   @click="clickSubmitAuditData(1, auditForm)">通 过</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 余额变动添加表单-->
    <el-dialog :title="dialogFonfigStream.streamName"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigStream.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="streamForm"
                  :formLabel="streamFormLabel"
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitStreamData(streamForm)">申 请</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 放大图片-->
    <el-dialog :visible.sync="dialogImgVisible"
               append-to-body>
      <img width="100%"
           :src="dialogImageUrl"
           alt="">
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button v-if="!streamBtn"
                     type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加数据代理商</el-button>
          <el-button v-if="streamBtn"
                     type="primary"
                     size="small"
                     @click="clickAddStreamData"
                     icon="el-icon-circle-plus-outline">余额流水</el-button>
        </div>
        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    ref="select"
                    class="filtrateInput">
          <el-button type="primary"
                     @click="searchHotelProductToAudit(searchForm)">搜 索</el-button>
          <el-button>导出</el-button>
          <el-button type="text">查看已导出列表</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>

      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTabForm class="content"
                       :tabLabel="tableFormLabel"
                       :tableData="tableData"
                       @changeSwitch="changeSwitch"
                       @clickEditData="clickEditData"
                       @clickWarningData="clickAuditData"
                       @clickDeleteData="clickDeleteData"
                       @clickRemarks='clickStream'
                       @clickInfoData='clickInfoData'
                       @tabHandleClick="swichTable"
                       @getPage="getPage"
                       @getNumArticle="getNumArticle"></CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>

import {
  getDataAgentList, methDataAgent, getDataAgentDetail, AuditDataAgent, changeDataAgent,
  getDataAgentChangeList, methDataAgentChange, streamAudit
} from 'api/organize.js'

import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'
import CommonAmap from 'components/CommonAmap.vue'


export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain,
    CommonTabForm,
    CommonAmap
  },
  data () {
    return {
      //主页面组件布局
      imgIndex: [],//获取删除后的图片
      searchData: '',//存放搜索的数据，分页时使用
      tabIndex: 0,//存放tab标签页的下标
      addBtn: true,//数据代理商增加按钮
      streamBtn: false,//余额流水增加按钮
      tableFormLabel: [
        {
          name: 'first',
          label: "待审核",
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '代理商ID',
              type: 'id',
              width: '100px'
            },
            {
              prop: 'nickname',
              label: '全称',
              width: '200px',
            },
            {
              prop: 'abbreviation',
              label: '简称',
              width: '200px',
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'name',
              label: '负责人',
            },
            {
              prop: 'entry_date',
              label: '创建时间',
            },
            {
              prop: 'models',
              label: '代理类型',
            },
            {
              prop: 'status',
              label: "状态",
              type: 'State'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                {
                  content: '审核',
                  type: 'warning'
                },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'second',
          label: "已启用",
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '代理商ID',
              type: 'id',
              width: '100px'
            },
            {
              prop: 'nickname',
              label: '全称',
              width: '200px',
            },
            {
              prop: 'abbreviation',
              label: '简称',
              width: '200px',
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'name',
              label: '负责人',
            },
            {
              prop: 'entry_date',
              label: '创建时间',
            },
            {
              prop: 'models',
              label: '代理类型',
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '禁用',
                  type: 'info'
                },
                {
                  content: '流水',
                  type: 'remarks'
                },
                {
                  content: '编辑',
                  type: 'primary'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'third',
          label: "已禁用",
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '代理商ID',
              type: 'id',
              width: '100px'
            },
            {
              prop: 'nickname',
              label: '全称',
              width: '200px',
            },
            {
              prop: 'abbreviation',
              label: '简称',
              width: '200px',
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'name',
              label: '负责人',
            },
            {
              prop: 'entry_date',
              label: '创建时间',
            },
            {
              prop: 'models',
              label: '代理类型',
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '启用',
                  type: 'info'
                },
                {
                  content: '流水',
                  type: 'remarks'
                },
                {
                  content: '编辑',
                  type: 'primary'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'fourth',
          label: "余额变动待审核",
          type: 'table',
          tableLabel: [
            {
              prop: 'abbreviation',
              label: '代理商简称',
              width: '200px'
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'change_no',
              label: '变更单号',
            },
            {
              prop: 'change_type',
              label: '变动类型',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
            },
            {
              prop: 'amount',
              label: '金额',
            },
            {
              prop: 'trade_no',
              label: '交易编号',
            },
            {
              prop: 'status',
              label: "状态",
              type: 'State'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '审核',
                  type: 'warning'
                },
                {
                  content: '删除',
                  type: 'danger'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'fifth',
          label: "余额变动已通过",
          type: 'table',
          tableLabel: [
            {
              prop: 'abbreviation',
              label: '代理商简称',
              width: '200px'
            },
            {
              prop: 'telephone',
              label: '联系方式'
            },
            {
              prop: 'change_no',
              label: '变更单号',
            },
            {
              prop: 'change_type',
              label: '变动类型',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
            },
            {
              prop: 'amount',
              label: '金额',
            },
            {
              prop: 'trade_no',
              label: '交易编号',
            },
            {
              prop: 'status',
              label: "状态",
              type: 'State'
            },

          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
      ],

      //对话框 表单组件
      TabsFormLabel: [
        {
          name: 'first',
          label: "基本信息",
          type: 'form',
          children: [ //form表单 组件   
            {
              model: 'nickname',
              label: '全称',
              width: '400px',
            },
            {
              model: 'abbreviation',
              label: '简称',
              width: '400px',
            },
            {
              model: 'telephone',
              label: '联系方式',
              width: '400px',
            },
            {
              model: 'license_number',
              label: '执照编号',
              width: '400px',
            },
            {
              model: 'license_img',
              label: '营业执照图片',
              type: 'uploadList',
              maxSize: 2048,
              imgHeight: '1280',
              imgWidth: '1200',
              tip: '213'
            },
            {
              model: 'entry_date',
              label: '入驻时间',
              width: '400px',
              type: 'datetime'
            },
            {
              model: 'name',
              label: '负责人',
              width: '400px',
            },
            {
              model: 'phone',
              label: '负责人手机',
              width: '400px',
            },
            {
              model: 'remarks',
              label: '备注',
              width: '400px',
              type: 'textarea'
            },
            {
              model: 'models',
              type: 'supplyType',
              options: [
                { id: 1, sType: '旅游' },
                { id: 2, sType: '酒店' },
                { id: 3, sType: '商城' },
                { id: 4, sType: '特权' },
                { id: 5, sType: '门票' },
                { id: 6, sType: '机票' },
              ],
              width: '400px'
            },
            {
              model: 'ase_key',
              label: '秘钥串',
              width: '400px',
            },
          ]
        },

      ],

      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增数据代理商',
        editName: '编辑数据代理商',
        isShowDialogVisible: false
      },
      // 对话框 Dialog 审核数据代理商
      dialogFonfigAudit: {
        auditName: '待审核',
        isShowDialogVisible: false
      },

      //form表单 审核
      auditForm: {
        id: '',
        remarks: '',
        status: ''
      },
      auditFormLabel: [
        {
          model: 'id',
          label: '数据代理商ID',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'remarks',
          label: '审核理由',
          type: 'textarea',
          width: '600px',
          required: true
        },

      ],

      // 对话框 Dialog 审核数据代理商
      dialogFonfigStream: {
        streamName: '数据代理商余额变动信息',
        isShowDialogVisible: false
      },

      //form表单 审核
      streamForm: {
        data_agent_id: '',
        change_type: '',
        pay_type: '',
        amount: ''
      },
      streamFormLabel: [
        {
          model: 'data_agent_id',
          label: '公司全称',
          width: '400px',
        },
        {
          model: 'change_type',
          label: '变动类型',
          type: 'select',
          width: '300px',
          options: [
            { value: 0, label: '充值' },
            { value: 1, label: '提现' },
          ]
        },
        {
          model: 'pay_type',
          label: '支付方式',
          type: 'select',
          width: '300px',
          options: [
            { value: 0, label: '下线转账' },
            { value: 1, label: '公户转账' },
          ]
        },
        {
          model: 'amount',
          label: '金额',
          width: '300px',
          tip: '￥'
        },
      ],

      //form表单 基础数据
      openForm: {
        //基础数据
        nickname: '',
        abbreviation: '',
        telephone: '',
        license_number: '',
        license_img: [],
        entry_date: '',
        name: '',
        phone: '',
        remarks: '',
        models: [],
        ase_key: '',
      },
      // form表单 搜索数据
      searchForm: {
        search: '',
        phone: '',
        models: '',
        change_type: '',
        pay_type: '',
        data_agent_id: ''
      },
      searchformLabel: [
        {
          model: 'search',
          label: '代理商名称',
          width: '304px',
        },
        {
          model: 'phone',
          label: '联系方式',
          width: '304px',
        },
        {
          model: 'models',
          label: '代理类型',
          width: '304px',
          type: 'select',
          options: [
            { value: 1, label: '旅游' },
            { value: 2, label: '酒店' },
            { value: 3, label: '商城' },
            { value: 4, label: '特权' },
            { value: 5, label: '门票' },
            { value: 6, label: '机票' },
          ],
        },
        {
          model: 'change_type',
          label: '变动类型',
          width: '304px',
          type: 'select',
          options: [
            { value: 0, label: '充值' },
            { value: 1, label: '提现' },
          ],
        },
        {
          model: 'pay_type',
          label: '支付方式',
          width: '304px',
          type: 'select',
          options: [
            { value: 0, label: '下线转账' },
            { value: 1, label: '公户转账' },
          ],
        },
        {
          model: 'data_agent_id',
          label: '代理商ID',
          width: '304px',
        },
      ],
      //内层Dialog
      inDialog: {
        title: '选择经纬度',
        isShowDialogVisible: false
      },

      // table 表格 基础数据
      tableData: [],
      // 酒店、民宿-全部-数据
      hotelAllDataObj: {},
      // catgory_id最大值
      MaxId: '',
      //待审核 或 正式运营 Name
      activeTableName: 'first',
      //Dialog新增/编辑 Name
      activeTabName: 'first',
      //Dialog 放大图片
      dialogImgVisible: false,
      dialogImageUrl: '',

      //提交保存信息的 按钮名称
      dialogBtnName: '下一步',
    }
  },
  beforeRouteEnter (to, from, next) {
    if (from.name == 'dataAgentStream') {
      to.meta.isBack = true;
      //判断是从哪个路由过来的，
      //如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
    }
    //继续执行
    next();
  },
  activated () {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
      // 执行自己写的页面的初始化
      this.updataTableData();
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false
    // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
    this.isFirstEnter = false;
  },
  created () {
    this.isFirstEnter = true;
    // 只有第一次进入或者刷新页面后才会执行此钩子函数，使用keep-alive后（2+次）进入不会再执行此钩子函数
  },
  mounted () {
    //初始table数据
    this.getDataAgentList();
  },

  watch: {
    //将字段转换为Elementui能用的字段
    hotelAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.hotelAllDataObj.list,
        {
          status: 'status',
        },
      )
    },
    activeTabName: function () {
      if (this.activeTabName == "fourth") {
        this.dialogBtnName = "保 存"
      } else {
        this.dialogBtnName = "下一步"
      }
    },
  },
  methods: {
    //处理时间戳
    formatDate (time) {
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate()
      return year + '-' + month + '-' + date
    },
    //获取复选框的ID
    getSupplyID (data) {
      if (data) {
        let arr = []
        data.forEach(item => {
          arr.push(item.supplyItemId)
        })
        for (var i = 0; i < arr.length; i++) {

          arr[i] = JSON.stringify(arr[i]);
        }
        console.log(arr)
        this.openForm.models = arr
        console.log(this.openForm.models)
      }
    },
    //数据代理商数据列表  请求接口
    getDataAgentList (data) {
      if (data) {
        getDataAgentList(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            DATA.data.list.forEach(item => {
              //处理供应类型数据
              for (let i = 0; i < item.models.length; i++) {
                if (item.models[i] == '1') {
                  item.models[i] = '旅游'
                } else if (item.models[i] == '2') {
                  item.models[i] = '酒店'
                } else if (item.models[i] == '3') {
                  item.models[i] = '商城'
                } else if (item.models[i] == '4') {
                  item.models[i] = '特权'
                } else if (item.models[i] == '5') {
                  item.models[i] = '门票'
                } else if (item.models[i] == '6') {
                  item.models[i] = '机票'
                }
              }
              item.models = item.models.join()
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[0].tableConfig.total = DATA.data.total
            this.tableFormLabel[0].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }

        })
      } else {
        getDataAgentList({
          status: this.tabIndex,
          page: this.tableFormLabel[this.tabIndex].tableConfig.page,
          page_rows: this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            DATA.data.list.forEach(item => {
              //处理供应类型数据
              for (let i = 0; i < item.models.length; i++) {
                if (item.models[i] == '1') {
                  item.models[i] = '旅游'
                } else if (item.models[i] == '2') {
                  item.models[i] = '酒店'
                } else if (item.models[i] == '3') {
                  item.models[i] = '商城'
                } else if (item.models[i] == '4') {
                  item.models[i] = '特权'
                } else if (item.models[i] == '5') {
                  item.models[i] = '门票'
                } else if (item.models[i] == '6') {
                  item.models[i] = '机票'
                }
              }
              item.models = item.models.join()
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
          }

        })
      }

    },


    //正式运营 已冻结 请求接口
    getDataAgentFrozen (data) {
      if (data) {
        getDataAgentList(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            DATA.data.list.forEach(item => {
              //处理供应类型数据
              for (let i = 0; i < item.models.length; i++) {
                if (item.models[i] == '1') {
                  item.models[i] = '旅游'
                } else if (item.models[i] == '2') {
                  item.models[i] = '酒店'
                } else if (item.models[i] == '3') {
                  item.models[i] = '商城'
                } else if (item.models[i] == '4') {
                  item.models[i] = '特权'
                } else if (item.models[i] == '5') {
                  item.models[i] = '门票'
                } else if (item.models[i] == '6') {
                  item.models[i] = '机票'
                }
              }
              item.models = item.models.join()
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[2].tableConfig.total = DATA.data.total
            this.tableFormLabel[2].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }

        })
      } else {
        getDataAgentList({
          status: 3,
          page: this.tableFormLabel[1].tableConfig.page,
          page_rows: this.tableFormLabel[1].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            DATA.data.list.forEach(item => {
              //处理供应类型数据
              for (let i = 0; i < item.models.length; i++) {
                if (item.models[i] == '1') {
                  item.models[i] = '旅游'
                } else if (item.models[i] == '2') {
                  item.models[i] = '酒店'
                } else if (item.models[i] == '3') {
                  item.models[i] = '商城'
                } else if (item.models[i] == '4') {
                  item.models[i] = '特权'
                } else if (item.models[i] == '5') {
                  item.models[i] = '门票'
                } else if (item.models[i] == '6') {
                  item.models[i] = '机票'
                }
              }
              item.models = item.models.join()
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[2].tableConfig.total = DATA.data.total
            this.tableFormLabel[2].tableConfig.loading = false
          }

        })
      }

    },

    //数据代理商数据余额变动待审核列表  请求接口
    getDataAgentChangeList (data) {
      if (data) {
        getDataAgentChangeList(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[3].tableConfig.total = DATA.data.total
            this.tableFormLabel[3].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }
        })
      } else {
        getDataAgentChangeList({
          page: this.tableFormLabel[this.tabIndex].tableConfig.page,
          page_rows: this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })

            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
          }
        })
      }

    },

    //数据代理商数据余额变动已通过列表  请求接口
    adoptGetDataAgentChangeList (data) {
      if (data) {
        getDataAgentChangeList(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }
        })
      } else {
        getDataAgentChangeList({
          status: 1,
          page: this.tableFormLabel[this.tabIndex].tableConfig.page,
          page_rows: this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })

            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
          }
        })
      }

    },

    //获得下列分类页的页数
    getPage () {
      if (this.activeTableName == 'first') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getDataAgentList(this.searchData)
        } else {
          this.getDataAgentList()
        }
      } else if (this.activeTableName == 'second') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getDataAgentList(this.searchData)
        } else {
          this.getDataAgentList()
        }
      } else if (this.activeTableName == 'third') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getDataAgentFrozen(this.searchData)
        } else {
          this.getDataAgentFrozen()
        }
      } else if (this.activeTableName == 'fourth') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getDataAgentChangeList(this.searchData)
        } else {
          this.getDataAgentChangeList()
        }
      } else if (this.activeTableName == 'fifth') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.adoptGetDataAgentChangeList(this.searchData)
        } else {
          this.adoptGetDataAgentChangeList()
        }
      }
    },

    //获取下面分页的页数的最大条数
    getNumArticle (NumArticle) {
      this.tableFormLabel[this.tabIndex].tableConfig.NumArticle = NumArticle
      if (this.activeTableName == 'first') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getDataAgentList(this.searchData)
        } else {
          this.getDataAgentList()
        }
      } else if (this.activeTableName == 'second') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getDataAgentList(this.searchData)
        } else {
          this.getDataAgentList()
        }
      } else if (this.activeTableName == 'third') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getDataAgentFrozen(this.searchData)
        } else {
          this.getDataAgentFrozen()
        }
      } else if (this.activeTableName == 'fourth') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getDataAgentChangeList(this.searchData)
        } else {
          this.getDataAgentChangeList()
        }
      } else if (this.activeTableName == 'fifth') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.adoptGetDataAgentChangeList(this.searchData)
        } else {
          this.adoptGetDataAgentChangeList()
        }
      }
    },
    //新增、 编辑数据代理商 请求接口
    methDataAgent (met, data) {
      return new Promise(resolve => {
        methDataAgent(met, data).then(res => {
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })
    },
    //新增余额流水信息 请求接口
    methDataAgentChange (met, data) {
      return new Promise(resolve => {
        methDataAgentChange(met, data).then(res => {
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
          } else {
            this.$message({
              message: DATA.message,
              type: 'error'
            })
          }
        })
      })
    },

    //审核数据代理商  接口
    AuditDataAgent (data) {
      return new Promise(resolve => {
        AuditDataAgent(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },

    //审核数据代理商余额变动  接口
    streamAudit (data) {
      return new Promise(resolve => {
        streamAudit(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },

    //运营列表中-变更启用/禁用状态 接口
    changeDataAgent (data) {
      changeDataAgent(data).then((res) => {
        const DATA = res.data
        console.log(res)
        if (DATA.code == 200) {
          this.$message({
            message: DATA.message,
            type: 'success'
          })
        } else {
          this.$message({
            message: DATA.message,
            type: 'warning'
          })
        }
      })
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 catgory_id值
    MaxCatgroyId (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    //切换tab 待审核、正式运营
    swichTable (tab) {
      const name = tab.name
      this.activeTableName = name
      this.tabIndex = tab.index
      if (this.activeTableName == "fourth" || this.activeTableName == 'fifth') {
        this.streamBtn = true
      } else {
        this.streamBtn = false
      }
      //刷新
      this.updataTableData();
    },
    //切换tab Dialog新增、编辑
    swichTab (tab) {
      const name = tab.name
      this.activeTabName = name
    },
    //刷新表格数据
    updataTableData () {
      if (this.activeTableName == "first") {
        //带审核
        this.getDataAgentList();
      } else if (this.activeTableName == "second") {
        //正式运营 已启用
        this.getDataAgentList();
      } else if (this.activeTableName == "third") {
        //正式运营 已禁用
        this.getDataAgentFrozen()
      } else if (this.activeTableName == "fourth") {
        //数据代理商余额变动待审核列表
        this.getDataAgentChangeList()
      } else if (this.activeTableName == 'fifth') {
        //数据代理商余额变动已通过列表
        this.adoptGetDataAgentChangeList()
      }

    },

    // 添加数据代理商数据操作
    clickAddData () {
      this.openForm = this.$options.data().openForm
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'add'
      // console.log(this.$refs.elTable.page)
    },
    //添加余额流水操作
    clickAddStreamData () {
      this.dialogFonfigStream.isShowDialogVisible = true;
    },

    // 确定提交数据操作
    async clickSubmitData (data) {

      const name = this.activeTabName;//当前Dialog Tab页面
      console.log(data)
      let res;//返回处理结果
      if (name == 'first') {//基本信息
        res = await this.saveMainnformation(data)
        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.dialogFonfig.isShowDialogVisible = false;
          this.openForm = this.$options.data().openForm;
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
          return;
        }

      }

    },
    //保存-基本信息
    async saveMainnformation (data) {
      let type = this.dialogFonfig.openType
      let result;//主信息上传是否成功

      if (type == 'add') {
        // 基本信息
        const DATA = {
          nickname: data.nickname,
          abbreviation: data.abbreviation,
          telephone: data.telephone,
          license_number: data.license_number,
          license_img: data.license_img,
          entry_date: data.entry_date,
          name: data.name,
          phone: data.phone,
          remarks: data.remarks,
          models: data.models,
          ase_key: data.ase_key,
        }
        DATA.entry_date = new Date(DATA.entry_date).getTime() / 1000
        console.log(DATA.entry_date)
        result = await this.methDataAgent('POST', DATA)
        this.updataTableData()
      } else if (type == 'edit') {
        this.imgIndex.forEach(item => {
          data.license_img.splice(this.imgIndex, 1)
        })
        const DATA = {
          id: data.id,
          nickname: data.nickname,
          abbreviation: data.abbreviation,
          telephone: data.telephone,
          license_number: data.license_number,
          license_img: data.license_img,
          entry_date: data.entry_date,
          name: data.name,
          phone: data.phone,
          remarks: data.remarks,
          models: data.models,
          ase_key: data.ase_key,
        }
        DATA.entry_date = new Date(DATA.entry_date).getTime() / 1000
        console.log(DATA)
        result = await this.methDataAgent('PUT', DATA)
        this.updataTableData()
      }
      return result;

    },

    //提交余额变动信息
    async clickSubmitStreamData (data) {
      console.log(data)
      const DATA = {
        data_agent_id: data.data_agent_id,
        change_type: data.change_type,
        pay_type: data.pay_type,
        amount: data.amount,
      }
      let res = await this.methDataAgentChange('POST', DATA)

      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success'
        })
        this.dialogFonfigStream.isShowDialogVisible = false;
        this.streamForm = this.$options.data().streamForm;
        this.updataTableData()
      } else {
        this.$message({
          message: res.message,
          type: 'error'
        })
        return;
      }

    },
    //流水按钮操作
    clickStream (row) {
      console.log(row)
      this.$router.push({ name: 'dataAgentStream', query: { dataAgent_id: row.id } })
    },
    // 取消提交数据操作
    clickCancelData () {
      this.activeTabName = "first"
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前添加数据代理商的初始化选项
      this.openForm = this.$options.data().openForm
      this.dialogFonfigAudit.isShowDialogVisible = false
      // 用于当前审核表单的初始化选项
      this.auditForm = this.$options.data().auditForm
      // 用于当前添加余额流水表单的初始化选项
      this.dialogFonfigStream.isShowDialogVisible = false
      this.streamForm = this.$options.data().streamForm
    },

    // 表格开关操作
    changeSwitch (row) {
      // id		数据代理商ID
      // status	int	状态 1上架 3下架
      const DATA = {
        id: row.id,
        status: row.status == 0 ? 3 : 1
      }
      console.log(DATA)
      this.changeDataAgent(DATA)
      this.updataTableData()
    },
    //禁用/启用按键
    clickInfoData (row) {
      console.log(row.status)
      if (row.status == 1) {
        const DATA = {
          id: row.id,
          status: 3
        }
        this.changeDataAgent(DATA)
      } else if (row.status == 3) {
        const DATA = {
          id: row.id,
          status: 1
        }
        this.changeDataAgent(DATA)
      }
      this.updataTableData()
    },
    //获取从CommonUploadList传来的图片删除数据
    handleRemove (data) {
      this.imgIndex.push(data)
    },
    // 编辑操作
    async clickEditData (row) {
      console.log(row)
      // id	是	数据代理商的Id
      const data = {
        id: row.id
      }
      await getDataAgentDetail(data).then((res) => {
        const DATA = res.data
        if (DATA.code == 200) {
          this.$message({
            message: DATA.message,
            type: 'success'
          })
          console.log(DATA)
        }
        this.imgIndex.forEach(item => {
          DATA.data.license_img.splice(this.imgIndex, 1)
        })
        this.imgIndex = []
        this.openForm = {
          id: DATA.data.id,
          nickname: DATA.data.nickname,
          abbreviation: DATA.data.abbreviation,
          telephone: DATA.data.telephone,
          license_number: DATA.data.license_number,
          license_img: DATA.data.license_img,
          entry_date: DATA.data.entry_date * 1000,
          name: DATA.data.name,
          phone: DATA.data.phone,
          remarks: DATA.data.remarks,
          models: DATA.data.models,
          ase_key: DATA.data.ase_key,
        }
      })
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },


    //审核操作
    clickAuditData (row) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // status	是	int	状态 1、通过 2、驳回
      // remarks	是	string	审核理由
      this.auditForm = {
        id: row.id,
        remarks: row.remarks
      }
      this.dialogFonfigAudit.isShowDialogVisible = true;
    },
    //审核结果提交
    async clickSubmitAuditData (statue, data) {
      // ----------------数据格式-----------------
      //id	是	int	ID
      // status	是	int	状态 3、通过 2、驳回
      const DATA = {
        id: data.id,
        status: statue,
        remarks: data.remarks
      }
      if (this.activeTableName == 'first') {
        await this.AuditDataAgent(DATA).then(async (res) => {
          if (res.code == 200) {
            await this.updataTableData();
          }
        });//调用审核接口 
      } else if (this.activeTableName == 'fourth') {
        await this.streamAudit(DATA).then(async (res) => {
          if (res.code == 200) {
            await this.updataTableData();
          }
        });//调用审核接口 
      }


      this.dialogFonfigAudit.isShowDialogVisible = false
    },

    // 删除操作
    clickDeleteData (row) {
      // ----------------数据格式-----------------
      //id	是	int	ID
      if (this.activeTableName == 'first') {
        this.$confirm('确定移除这条记录吗？', '提示', { type: "error" }).then(() => {
          const DATA = {
            id: row.id
          }
          methDataAgent('DELETE', DATA).then((res) => {
            const DATA = res.data
            if (DATA.code == 200) {
              this.$message.success('删除成功！');
              this.updataTableData();
            } else {
              this.$message({
                message: DATA.message,
                type: 'error'
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        })
      } else if (this.activeTableName == 'fourth') {
        this.$confirm('确定移除这条记录吗？', '提示', { type: "error" }).then(() => {
          const DATA = {
            id: row.id
          }
          methDataAgentChange('DELETE', DATA).then((res) => {
            const DATA = res.data
            if (DATA.code == 200) {
              this.$message.success('删除成功！');
              this.updataTableData();
            } else {
              this.$message({
                message: DATA.message,
                type: 'error'
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        })
      }

    },

    // 查询操作
    searchHotelProductToAudit (searchForm) {
      const data = {
        search: searchForm.search,
        phone: searchForm.phone,
        models: searchForm.models,
        change_type: searchForm.change_type,
        pay_type: searchForm.pay_type,
        data_agent_id: searchForm.data_agent_id
      }

      this.searchData = data
      data.page = this.tableFormLabel[this.tabIndex].tableConfig.page
      data.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
      console.log(data)
      if (this.activeTableName == 'first') {
        data.status = this.tabIndex
        this.getDataAgentList(data)
      } else if (this.activeTableName == 'second') {
        data.status = this.tabIndex
        this.getDataAgentList(data)
      } else if (this.activeTableName == 'third') {
        data.status = this.tabIndex
        this.getDataAgentFrozen(data)
      } else if (this.activeTableName == 'fourth') {
        // data.status = this.tabIndex
        this.getDataAgentChangeList(data)
      } else if (this.activeTableName == 'fifth') {
        data.status = 1
        this.adoptGetDataAgentChangeList(data)
      }
      console.log(searchForm)
    },
    //重置筛选条件操作
    resetFilter (searchForm) {
      searchForm.search = '',
        searchForm.phone = '',
        searchForm.models = '',
        searchForm.change_type = '',
        searchForm.pay_type = '',
        searchForm.data_agent_id = ''
      if (this.activeTableName == 'first') {
        this.getDataAgentList()
      } else if (this.activeTableName == 'second') {
        this.getDataAgentList()
      } else if (this.activeTableName == 'third') {
        this.getDataAgentFrozen()
      } else if (this.activeTableName == 'fourth') {
        this.getDataAgentChangeList()
      } else if (this.activeTableName == 'fifth') {
        this.adoptGetDataAgentChangeList()
      }
    },
    //点击放大图片
    handlePreview (file) {
      this.dialogImageUrl = file.url
      this.dialogImgVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .content {
    height: 100%;
  }
  /deep/ .el-tabs__content {
    height: 96.4%;
  }
  .dialogAudit {
    /deep/ .el-dialog__body {
      padding-right: 45%;
    }
  }
  /deep/ .el-table .cell.el-tooltip {
    white-space: normal;
    text-align: left;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 24px 8px;
    margin-top: 51px;
    width: 100%;
    margin-left: -128px;
    margin-bottom: 32px;
  }
  /deep/ .el-dialog {
    width: 700px;
  }
}
</style>